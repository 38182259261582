<template>

         <div @click="goBack" id="back-button" v-bind:class="[isMobile ? 'back-button--m' : 'back-button--d']"
         >
            <v-btn icon
            class="navigation-arrow"
            >
                <i class="material-icons icon">keyboard_arrow_left</i>
            </v-btn>

        <h4>{{$t('actions.back')}}</h4>

         </div>

</template>

<script>
import {mapGetters} from 'vuex'

export default {
    methods: {
        goBack: function () {

            const route = this.$router.currentRoute

            // console.log(this.$router.currentRoute)

            if (route.name == 'collection') {
                this.$router.push('/')

            } else if (route.name == 'recording') {

                // console.log(this.$router.currentRoute.params)

                this.$router.push({ name: 'collection', params: { collID: this.$router.currentRoute.params.collID } })

            } else if (route.name == 'account') {

                this.$router.push({ name: 'home' })

            } else {

                this.$router.back()
            }
        },
    },
    computed: {
    ...mapGetters({
        windowWidth: 'WIDTH',
    }),
    isMobile () {
        return (this.windowWidth < 600) ? true : false
    },
    }
}
</script>

<style scoped>
    #back-button {
        position:absolute;
        left: 10px;
        top: 25px;
        width: 100px;
        display: flex;
        justify-content: flex-start;
        padding-left: 32px;
        cursor: pointer;
    }

    #back-button h4 {
        display: inline-block;
        padding: 14px 0;
        margin-left: -5px;
    }

    .back-button--m {
        opacity: 0;
        pointer-events: none;
    }

    /* .back-button--d {
        opacity: 0;

    } */




</style>

