<template>

    <div>

     <div class="title-bar">
        <app-back-button></app-back-button>
        <div class="page-title">
            <h2 class="main-page-title">Uploads</h2>

            </div>

        </div>

        <div v-show="currentUploads == 0" class="description  slow-fade" >

            <h2>No Current Uploads</h2>
      
          <i 
          @click="showHelp = !showHelp"
          class="material-icons icon desc-icon">help_outline</i>

            <div v-show="showHelp">
                <p>Upload your audio and video files in the Recordings section above. Once uploaded, we will transribe and optimize your files so you can search, tag and share.</p>
                <a>Learn more about Recordings in our help section</a>
            </div>
          </div>

        <div v-show="currentUploads > 0" id="dash-modal">
            <div id="uppy-dash"></div>
        </div>
    </div>

</template>


<script>
import uppyAll from './../uppy'
import BackButton from './../components/BackButton'


export default {
    data () {
        return {
            showDash: false,
            showHelp: true,
        } 
    },
    components: {
        appBackButton : BackButton
    },
    methods: {
        // getStatus: function () {
        //     const upState = uppyAll.uppyOne.getState()
        // }
    },
    computed: {
        currentUploads () {
            return this.$store.getters.CURRENT_UPLOADS
        },
    },
    mounted () {
    this.$store.dispatch('CHANGE_HEIGHT', 200)
    const Dashboard = require('@uppy/dashboard')
    require('@uppy/dashboard/dist/style.css')
    require('@uppy/core/dist/style.css')

      uppyAll.uppyOne.use(Dashboard, {
        id: 'OneDash',
        inline: true,
        target: '#uppy-dash',
        trigger: '#uppy-dash',
        width: 750,
        height: 550,
        thumbnailWidth: 280,
        showLinkToFileUploadResult: true,
        showProgressDetails: true,
        hideUploadButton: true,
        hideRetryButton: false,
        hidePauseResumeButton: false,
        hideCancelButton: false,
        hideProgressAfterFinish: false,
        closeModalOnClickOutside: false,
        closeAfterFinish: false,
        disableStatusBar: false,
        disableInformer: false,
        disableThumbnailGenerator: false,
        proudlyDisplayPoweredByUppy: false,
        onRequestCloseModal: () => this.closeModal(),
        showSelectedFiles: true,
        })
    },
    beforeDestroy () {
        const dashInstance = uppyAll.uppyOne.getPlugin('OneDash')
        uppyAll.uppyOne.removePlugin(dashInstance)
    }
}

</script>

 <style scoped>


.page-title { 
    flex-grow: 2;
    display: flex;
    justify-content: center;
}

#dash-modal {
    display: flex;
    align-items: center;
    margin: 0 auto;
    width: 50%;
}

.description h2 {
    margin-bottom: 100px;
}

.description {
    margin-top: 200px;
}

</style>

<style>

#app .uppy-size--md .uppy-DashboardContent-addMore {
    opacity: 0;
    pointer-events: none;
}

#app .uppy-size--md .uppy-DashboardItem-action--copyLink {
    opacity: 0;
    pointer-events: none;
}

</style>